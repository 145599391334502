'use client';

import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';
import AmountRaisedInInterval from './src/components/AmountRaisedLastHour';
import { BuilderCampaignsCarouselInputs } from './src/lib';
import { Intervals, LeaderboardType } from './src/types';

Builder.registerComponent(AmountRaisedInInterval, {
  name: 'Amount raised in interval',
  inputs: [
    {
      name: 'intervalType',
      friendlyName: 'Interval Type',
      type: 'string',
      enum: Object.values(Intervals),
      defaultValue: Intervals.HOUR,
    },
    {
      name: 'intervalDuration',
      friendlyName: 'Interval Duration',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'text',
      type: 'string',
      defaultValue: 'raised in the past hour',
    },
  ],
});

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/SitewideHeader/SitewideHeader')),
  {
    name: 'SitewideHeader',
    inputs: [
      {
        name: 'darkMode',
        helperText: 'If true, the icons will be white.',
        type: 'boolean',
        defaultValue: false,
      },
      {
        name: 'logoStyle',
        type: 'string',
        defaultValue: 'black',
        enum: ['white', 'black'],
      },
    ],
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/Footer/Footer')),
  {
    name: 'footer',
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/Qurbani/QurbaniTool')),
  {
    name: 'Qurbani Tool',
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/CountdownTimer/CountdownTimer')),
  {
    name: 'Countdown Timer',
    inputs: [
      {
        name: 'endTime',
        friendlyName: 'End date',
        type: 'date',
      },
      {
        name: 'textColor',
        friendlyName: 'Text color',
        type: 'color',
        defaultValue: '#FFFFFF',
        required: false,
      },
    ],
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(
    () =>
      import('./src/components/CompetitionLeaderboard/CompetitionLeaderboard'),
  ),
  {
    name: 'Competition Leaderboard',
    image: 'https://launchgood.s3.amazonaws.com/static/graphics/trophy.png',
    inputs: [
      {
        name: 'competitionId',
        friendlyName: 'Competition ID',
        type: 'number',
        required: true,
      },
      {
        name: 'leaderboardType',
        friendlyName: 'Leaderboard Type',
        type: 'string',
        enum: Object.values(LeaderboardType),
        required: true,
      },
    ],
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(
    () => import('./src/components/CampaignOrOrgCardBuilderComponent/index'),
  ),
  // dynamic(() => import('./src/components/CampaignCard/CampaignCardCarousel')),
  {
    name: 'Featured Campaigns Carousel',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136',
    inputs: [
      {
        name: 'docType',
        friendlyName: 'Card Type',
        type: 'string',
        enum: ['campaign', 'project', 'organization', 'community'], // 'project' value left for backward compatibility.
      },
      {
        name: 'adSpotType',
        friendlyName: 'Ad Spot Type',
        type: 'string',
        enum: ['general', 'ramadan', 'dhulhijah', 'zakat'],
      },
      {
        name: 'limit',
        friendlyName: 'Limit',
        type: 'number',
      },
      {
        name: 'offset',
        friendlyName: 'Offset',
        type: 'number',
      },
      {
        name: 'closedCampaigns',
        friendlyName: 'Closed Campaigns',
        type: 'boolean',
        defaultValue: true,
      },
      {
        name: 'src',
        helperText: 'Just the src value, not the full "?src=xxx"',
        type: 'string',
      },
    ],
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/CampaignCard/PopularNearYou')),
  {
    name: 'Popular Near You Campaigns',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136',
    inputs: BuilderCampaignsCarouselInputs,
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/CampaignCard/DiscoverCampaigns')),
  {
    name: 'Discover Campaigns Carousel',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136',
    inputs: [
      ...BuilderCampaignsCarouselInputs,
      {
        name: 'style',
        friendlyName: 'Style',
        type: 'string',
        enum: ['card', 'list'],
        defaultValue: 'card',
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(() => import('./src/components/CampaignCard/TrendingCampaigns')),
  {
    name: 'Trending Campaigns',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136',
    inputs: [
      {
        name: 'noOfCampaigns',
        friendlyName: 'Number of Campaigns',
        helperText: 'How many campaigns would you like to load?',
        type: 'number',
        required: false,
      },
      {
        name: 'pastHours',
        friendlyName: 'Time span (in hours)',
        helperText: 'e.g. "24" for the past 24 hours',
        type: 'number',
        required: false,
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(
    () =>
      import('./src/components/CampaignCard/RecommendedCampaigns/ByCategory'),
  ),
  {
    name: 'Recommended Campaigns by Category',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136',
    inputs: [
      {
        name: 'src',
        helperText: 'Just the value of the src, ie. the part after "?src="',
        type: 'string',
      },
    ],
  },
);

Builder.registerComponent(
  dynamic(
    () => import('./src/components/CampaignCard/RecommendedCampaigns/ByEngine'),
  ),
  {
    name: 'Recommended Engine',
    image:
      'https://cdn.builder.io/api/v1/image/assets%2Fc05b786f1645447ab878b73ca4dd6870%2Fe90fd83f13054d27b1d9f221c5cf8136',
    inputs: [
      {
        name: 'src',
        helperText: 'Just the value of the src, ie. the part after "?src="',
        type: 'string',
      },
    ],
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/DiscoverExperience/SearchBar')),
  {
    name: 'Discover: Search Bar',
    image: 'https://launchgood.s3.amazonaws.com/static/icons/search-icon.svg',
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/GivingStream/index')),
  {
    name: 'Giving Stream',
    inputs: [
      {
        name: 'campaignId',
        friendlyName: 'Campaign ID',
        type: 'number',
        required: false,
      },
      {
        name: 'textColor',
        friendlyName: 'Campaign Text color',
        type: 'color',
        defaultValue: '#FFFFFF',
        required: false,
      },
    ],
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/AllTimeStats/index')),
  {
    name: 'All Time Stats',
    noWrap: true,
  },
);

Builder.registerComponent(
  // throws error for some reason, but this is how its done
  // @ts-ignore
  dynamic(() => import('./src/components/Confetti/index')),
  {
    name: 'Confetti!',
    noWrap: true,
    inputs: [
      {
        name: 'numberOfPieces',
        type: 'number',
        helperText: 'Number of pieces of confetti to display.',
        max: 1000,
        defaultValue: 200,
      },
      {
        name: 'recycle',
        type: 'boolean',
        helperText: 'If true, the confetti will keep coming in a loop.',
        defaultValue: false,
      },
    ],
  },
);
